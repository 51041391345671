import React, { useContext } from 'react'
import { ButtonContent } from '../global/boton/ButtonContent'
import { GlobalDataContext } from '../../context/context'
import { BiChevronRightSquare } from 'react-icons/bi'

const CallToAction = ({ title, texts, images }) => {
  const { rpdata } = useContext(GlobalDataContext)
  return (
    <section
      className='svgTriangulotopBgWhite svgTriangulotop w-full h-full bg-cover bg-no-repeat bg-center relative pt-10 pb-20 flex justify-center items-center'
      style={{
        backgroundImage: `url(${images})`,
        backgroundAttachment: 'fixed'

      }}
    >
      <div className='flex justify-center items-center w-[90%] md:w-[97%] mx-auto h-full relative svgTriangulotopBG'>
        <div className='w-4/5 mx-auto flex flex-col items-center text-center gap-5 relative text-white pt-28 pb-16'>
          <h1 className='text-[30px] md:text-[50px]'>{title}</h1>
          <p>{texts}</p>
          <h1 className='text-[30px] md:text-[30px]'>OUR CORE VALUES:</h1>
          <div className='w-full'>
            <ul className='grid grid-cols-1 md:grid-cols-2 gap-3 w-full pb-5 text-start'>
              {
                rpdata?.dbAbout?.slice(3, 11).map((item, index) => {
                  return (
                    <div>
                      <div className='flex space-x-2 items-center'>
                        <BiChevronRightSquare className='text-white text-[20px]' />
                        <h3 className='font-bold text-white text-[16px]' key={index}>{item.text}:</h3>
                      </div>
                      <p className='px-10'>{item.list}</p>
                    </div>
                  )
                })
              }
            </ul>

          </div>
          <div>
            <ButtonContent btnclases={'botonWhite'} btnStyle={'three'} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction