import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

import { ButtonContent } from '../global/boton/ButtonContent'

import { HiChevronRight } from 'react-icons/hi'
import { BiChevronRightSquare } from 'react-icons/bi'

const BlockContent = ({ title, sloganPrincipal, listServices, listAbout, texts, images, images2 }) => {

  const { rpdata } = useContext(GlobalDataContext)



  return (
    <section className='flex flex-col items-start mb-20 md:mb-0'>

      <div className='w-full px-10 pt-10 md:pt-0'>
        <div className='w-full mt-0 md:my-20 mx-auto flex flex-col gap-10 text-center '>
          {
            sloganPrincipal ?
              rpdata?.dbPrincipal?.licensed ?
                <h1 className='text-[35px] md:text-[60px]'>{rpdata?.dbPrincipal?.licensed}</h1>
                :
                <h1 className='text-[35px] md:text-[60px]'>
                  {
                    rpdata?.dbPrincipal?.exprYears ?
                      `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                      : 'we have many years of experience'
                  }
                </h1>
              :
              <h1 className='text-[35px] md:text-[60px]'>{title}</h1>
          }
          <p>{texts}</p>

          {
            listServices ?
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-5 pb-5">
                {rpdata?.dbServices?.slice(0, 8).map((item, index) => {
                  return (
                    <li key={index} className="flex items-center gap-3">
                      <BiChevronRightSquare className='titleColorIcons' />
                      <span>{item.name}</span>
                    </li>
                  )
                })}
              </ul>
              : null
          }

          {
            listAbout ?
              <ul className='grid grid-cols-1 md:grid-cols-2 gap-5 pb-5'>
                {
                  rpdata?.dbAbout?.[0].list.length > 1 ?
                    rpdata?.dbAbout?.[0].list.map((item, index) => {
                      return (

                        <li key={index} className="flex items-center gap-1">
                          <HiChevronRight className='titleColorIcons' />
                          <span>{item}</span>
                        </li>
                      )
                    })
                    : null
                }
              </ul>
              : null
          }
        </div>
      </div>
      <div className='flex  w-full h-full -mt-28'>
        <div
          className="bg-cover bg-center bg-no-repeat w-full md:w-[50%] py-[200px] md:py-0 h-full md:h-screen"
          style={{
            backgroundImage: `url(${images})`,
            clipPath: 'polygon(0 0, 75% 27%, 75% 90%, 0% 100%)'
          }}
        ></div>
        <div className="w-full md:w-[30%] px-5 mx-0 pt-16 flex flex-col justify-start items-center">
          <div className="text-center py-5">
            <ButtonContent btnStyle={'three'} />
          </div>
          <div className='w-full h-full '>
            <picture className="flex justify-center items-center w-full h-full">
              <img
                src={rpdata?.dbPrincipal?.logo}
                alt='logo'
                className="w-[100%] "
              />
            </picture>
          </div>

        </div>
        <div
          className="hidden md:block bg-cover bg-center bg-no-repeat w-full md:w-[50%] py-[200px] md:py-0 h-full md:h-screen"
          style={{
            backgroundImage: `url(${images2})`,
            clipPath: 'polygon(26% 27%, 100% 0, 100% 100%, 25% 90%)'
          }}
        ></div>
      </div>




    </section>
  )
}

export default BlockContent